import react from "react";

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Signup from "./signup";
import Login from "./login";
import Home from "./home";
import Exam from "./exam";
import Intro from "./register";
import LessonTwo from "./lesson2";


export default function App() {
    return (
        
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Intro/>}></Route>
                    <Route path="/signup" element={<Signup/>}></Route>
                    <Route path="/home" element={<Home/>}></Route>
                    <Route path="/login" element={<Login/>}></Route>
                    <Route path="/lesson2" element={<LessonTwo/>}></Route>

                    <Route path="/exam/:id" element={<Exam/>}></Route>


                </Routes>
            </BrowserRouter>
        </>
    )
}