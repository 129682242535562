import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './frontend/app';
import { AppProvider } from './context';
import "./style.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <AppProvider>
    <App/>
  </AppProvider>
);




