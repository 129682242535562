import React from 'react';
import axios from 'axios';


import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import catdata from "../examQuestion/cat1";

export default function Exam() {
  const gradeLevel = ["GRADE 1", "GRADE 2", "GRADE 3", "GRADE 4", "GRADE 5"]
  const [rstButton, setrstButton] = React.useState(false)
  const [rstPage, setrstPage] = React.useState(true)
  const [reply, setReply] = React.useState(false)

  const [data, setData] = React.useState([{
    email: "",
    name:"",
    exam1: ""

  }])

  // email variables
  const [mail, setml] = React.useState()
  const [nem, setnem] = React.useState()
  const [paper1, setpaper] = React.useState()


  let cat1 = 4 + 13;

  const {id} = useParams();
  const navigate = useNavigate();
  // @@@@@@@@@@@@ CAT1 @@@@@@@@@@@@@@@

 
    const [qscore, setQscore] = React.useState("")
    const [showers, setShowers] = React.useState(0)
    let realScore = 0


    let myQuestions = ""
    myQuestions =  catdata.map((questItem, qIndex)=> {
        const {id, q, a, correct} = questItem

        

        let answers = ""
        answers = a.map((ans, aIndex)=> {
            
            return (
                <li className="questList">
                    <label>
                        <input 
                            type="radio" 
                            name={`question-${qIndex}`}
                            data-correct= {`${questItem.correct === aIndex}`}
                            className="radioBtn"
                            
                        />
                        {ans}
                    </label>
                </li>
            )
        })
        return (
            <div key={id} className='quest-div'>
                <h3 className="theQuestion">{q}</h3>
                {answers}
            </div>
                
            
        )
    })
    

  React.useEffect(()=> {

    axios.get(`https://ng.nickngesa.co.ke/college_std/${id}`)
    .then((res)=>{
        setData(res.data)

    })
    .catch((err)=>console.log(err))

}, [id])

  React.useEffect(()=> {
    setml(data[0].email)
    setnem("Lesson 1 Results")
    setpaper(`Hello ${data[0].name}, you've scored ${data[0].exam1}%`)


  }, [data])


  // #########################
  function Submit4m(event) {
    event.preventDefault()
    const selectedInputs = event.target.querySelectorAll("input[type=radio]:checked")
  
    selectedInputs.forEach((selectedRadio)=> {
       
        if (selectedRadio.dataset.correct === "true") {
            console.log("correct")
            realScore += 1
        } else if (selectedRadio.dataset.correct === "false") {
            console.log("wrong")
            realScore = realScore
        }
    })

    let percentScore = Math.floor((realScore / 5) * 100)

    setData([{...data[0], exam1: percentScore}])

    setShowers(percentScore)
    setrstButton(true)
  
  
}
// @@@@@@@@@@@@ END of CAt @@@@@@@@@@@@@

  // #################################
  function Submit() {
    axios.put('https://ng.nickngesa.co.ke/score/'+ id, data[0])
      .then((res)=> {
        //  navigate("/home")
         console.log("Exam updated")
      })
      .catch(err=>console.log(err))
    setrstPage(false)
    setrstButton(false)
  }

  // @@@@@@@@@@@  Sending email @@@@@@@@@@@@@

  function sendMail() {
    axios.get("https://ng.nickngesa.co.ke/mail", {
      params: {
        mail,
        nem,
        paper1,
      },
    })
    .then(() => {
      console.log("success");
    })
    .catch(() => {
      console.log("exam email not sent");
    })
    setReply(true)

  }

  return (
    
    <div className='exam-div'>
                
      {rstPage ? 
        <form onSubmit={Submit4m} className="displace-form">
          <h1>Attempt all Questions</h1>
          <hr/>
          {myQuestions}
          <hr/>
          <button className="quiz-btn" type="submit" >Submit</button>
        </form>: <div className='resultDiv'>
          <div className='rst-div'>
            <h1>Score: {showers}%</h1>
            <button onClick={sendMail} className='mailBtn'>Send to Email</button>
            {reply ? <p className='replyStatement'>results sent to your email!!</p>: ""}
          </div>
          <Link to="/lesson2" className='lesson2Btn'>lesson 2</Link>
          </div>}
      
      <div className='showRtsBtn'>
        {
          rstButton ? <button onClick={Submit} className='showResults'>Show Results</button>: ""
        }
        
      </div>
  
    </div>
  )
}