export default [
  
  {
    id: 1,
    q: "1) Which country is not found in the African continent?",
    a: [
        "Moroco",
        "Syschelles",
        "Sudan",
        "Guatemala"
    ],
    correct: 3
  },
  {
    id: 2,
    q: "2) Which is not a county in Kenya?",
    a: [
        "Eldoret",
        "Kericho",
        "Muranga",
        "Busia"

    ],
    correct: 0
  },
  {
    id: 3,
    q: "3) Which is not a kenya city?",
    a: [
        "Nakuru",
        "Mombasa",
        "Kisumu",
        "Garisa"

    ],
    correct: 3
  },
  {
    id: 4,
    q: "4) Identify the tallest mountain",
    a: [
        "Mt.Elgon",
        "Mt.Kilimanjaro",
        "Mt.Kenya",
        "Mt.Morogoro"
    ],
    correct: 1
  },
  {
    id: 5,
    q: "5) Which river pours in the Indian Ocean",
    a: [
        "Niger",
        "Congo",
        "Tana",
        "Nile"
    ],
    correct: 2
  }
]