import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useGlobalContext } from '../context';


export default function Login() {
    const {regno, setRegno} = useGlobalContext();
    const [values, setValues] = React.useState(
        {
            regno: "",
            password: ""
        }
    )

    const navigate = useNavigate();

    function HandleInput(event) {
        setValues(prev => (
            {
                ...prev,
                [event.target.name]:[event.target.value]
            }
        ))
    }

    function HandleSubmit(event) {
        event.preventDefault();
        
        axios.post("https://ng.nickngesa.co.ke/login", values)
        .then((res)=> {
            if (res.data === "Success") {
                navigate("/home");
            } else {
                alert("No record existed")
            }
        })
        .catch(err=>console.log(err))
        setRegno(values.regno)
    }
    console.log(regno[0])

    return (
        <main className='login-main'>
            <div className='login-div'>
                <h2>--login section--</h2>
                <form action='' onSubmit={HandleSubmit}>
                
                    <div className='inp-divs'>
                        <p className='align-left'>Registration number</p>
                        <input className="all-inputs" type='number' name='regno' onChange={HandleInput} placeholder="enter regno e.g 372"/>
                    </div>
                    <div className='inp-divs'>
                        <p className='align-left'>Password</p>
                        <input className="all-inputs" type='password' onChange={HandleInput} name='password' placeholder="enter password e.g 4040"/>
                    </div>
                <button className='login-btn' type='submit'>Login</button> <br/>
                <p className='or'>OR</p>
                <Link to="/signup" className='create-account'>Create account</Link>

                </form>
            </div>
            
        </main>
    )
}