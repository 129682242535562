import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../context';

export default function Signup() {
    const {regno, setRegno} = useGlobalContext();
    const gradeLevel = ["--select--","GRADE 1", "GRADE 2", "GRADE 3", "GRADE 4", "GRADE 5"]
    const [values, setValues] = React.useState(
        {
            name: "",
            regno: "",
            email: "",
            password: "",
            stGrade: "",
            exam1: "",
            exam2: ""
        }
    )

    const navigate = useNavigate();

    function HandleInput(event) {
        setValues(prev => (
            {
                ...prev,
                [event.target.name]:[event.target.value]
            }
        ))
    }
    // console.log(values)
    function HandleSubmit(event) {
        event.preventDefault();
        axios.post("https://ng.nickngesa.co.ke/college", values)
        .then((res)=> {
            navigate("/home")
        })
        .catch(err=>console.log(err))
        setRegno(values.regno)
        
    }

    return (
        <main className='signup-main'>
            <div className='signup-div'>
                <h2>Sign Up</h2>
                <form action='' onSubmit={HandleSubmit}>
                    <div className='inp-divs'>
                        <p className='align-left'>Name</p>
                        <input className="all-inputs" type='text' onChange={HandleInput} name='name' placeholder='enter name e.g Jane'/>
                    </div>
                    <div className='inp-divs'>
                        <p className='align-left'>Registration number</p>
                        <input className="all-inputs" type='number' name='regno' onChange={HandleInput} placeholder="enter regno e.g 372"/>
                    </div>
                    
                    <div className='inp-divs'>
                        <p className='align-left'>Email</p>
                        <input className="all-inputs" type='email' onChange={HandleInput} name='email' placeholder="enter a valid email"/>
                    </div>
                    <div className='inp-divs'>
                        <p className='align-left'>Password</p>
                        <input className="all-inputs" type='password' onChange={HandleInput} name='password' placeholder="enter password e.g 4040"/>
                    </div>
                    <div className='inp-divs'>
                        <p className='align-left'>Choose Grade</p>
                        <select className="all-inputs" name='stGrade'  placeholder = "Select" onChange={HandleInput}>
                            {gradeLevel.map((grade)=> {
                            return <option key={grade}>{grade}</option>
                            })}
                        </select>
                    </div>
                    
                    <button className='signup-btn' type='submit'>Sign Up</button> <br/>
                    <p className='or'>OR</p>
                    <Link to="/login" className='log-account'>Login</Link>
                    
                </form>
            </div>
            
        </main>
    )
}