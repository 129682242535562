import React from 'react';
import { Link } from 'react-router-dom';

import photo from "./OnlineExam1.png"

export default function Intro() {
  const [menuOpen, setMenu] = React.useState(false) 

  function ChangeMenu() {
    setMenu(!menuOpen)
    console.log("menu changed")
  }
  return (
    <section className='Regsection'> 
      <nav>
        <div><h1 className='digitest'>DIGI<span className='class-logo'>Class</span></h1></div>
        <div>
          <span className='lessons'>Lessons</span>
          <span>Resources</span>
        </div>
        <div className= {menuOpen ? 'openM': 'logReg'}>
          <Link to="/login" className='links link-login'>Login</Link>
          <Link to="/signup" className='links link-register'>Register</Link>
        </div>
        <div className='digiMenu' onClick={ChangeMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>

      <main className='Geo-main'>
        <div className='Geography'>
          <div className='Geo-class'>
            <h1 >Online Geography Class</h1>
            <p>Secure, easy-to-use, dedicated to your success. Test the leading system for online education in terms of user experience and reliability. Power your whole education process and ensure academic success at all times.</p>
          </div>
          <div><img src={photo}/></div>
        </div>
        <Link to="/login" className='links link-register'>Get Started</Link>
      </main>
    </section>
  )
}