import React from 'react';

import { Link } from 'react-router-dom';



export default function LessonTwo() {
  return (
    <div className='lesson2Div'>
      <h1 className='comingsoon'>Coming soon...</h1>
      <Link to="/" className='links link-register'>Home</Link>

    </div>
  ) 
    
}