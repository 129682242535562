import React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom'

import { useGlobalContext } from '../context';

import water from "./notes/water.pdf"
import land from "./notes/land.pdf" 
import latitude from "./notes/latitude.pdf" 
import climate from "./notes/climate.pdf" 

// VIDEOS

import waterBD from "./videos/WatBD.mp4"
import landSP from "./videos/LandSP.mp4"
import longlat from "./videos/Longlat.mp4"
import climCH from "./videos/ClimCH.mp4"


export default function Home() {
    const [stdName, setStdname] = React.useState("")
   const {regno} = useGlobalContext();

// @@@@@@@@@@@@@@@@@@@@@ Fetch from Database @@@@@@@@@@@@@@@@@@

   const [data, setData] = React.useState([])
   const [newArray, setArray] = React.useState([{name: ""}])
    React.useEffect(()=> {
        axios.get("https://ng.nickngesa.co.ke/college/")
        .then((res)=>{
            setData(res.data)
            setArray(res.data.filter((old)=> old.regno == regno))

        })
        .catch((err)=>console.log(err))

        
    }, [newArray])

    return (
      <div className='homepage'>
          
          <div>
            <h1>Welome to the Geography course, <span className='std-name'>{newArray[0].name}</span></h1>
            <p>This course is made up of modules such as Landscape, Water bodies, Weather patterns, Climate change, Longituteds and Latitudes. Its covered under five lessons with exams. Attempt an exam after every lesson. Exam results will be displayed immediately with further option of sending them to your email as provided in the signup form. Reading materials are provided in the form of PDFs and videos. </p>
            <span className='std-name'>Best Wishes!!</span>
            <hr/>
            <h2 className='lesson-1'>Lesson 1</h2>
            <div className='pdf'>
                <div className='ind-pdf'><a href={water} target='_blank'>(PDF)Module 1: Water...</a></div>
                <div className='ind-pdf'><a href={land} target='_blank'>(PDF)Module 2: Landsc...</a></div>
                <div className='ind-pdf'><a href={climate} target='_blank'>(PDF)Module 3: Climate...</a></div>
                <div className='ind-pdf'><a href={latitude} target='_blank'>(PDF)Module 4: Longitu...</a></div>
                
            </div>
            <h3>Lesson videos</h3>
            <div className='videos'>
                <div className='vid-divs'>
                    <p>water bodies</p>
                    <video controls src={waterBD} width="300px" height="200px"></video>
                </div>
                <div className='vid-divs'>
                    <p>landscapes</p>
                    <video controls src={landSP} width="300px" height="200px"></video>
                </div>
                <div className='vid-divs'>
                    <p>climate change</p>
                    <video controls src={climCH} width="300px" height="200px"></video>
                </div>
                <div className='vid-divs'>
                    <p>longitudes & latitudes</p>
                    <video controls src={longlat} width="300px" height="200px"></video>
                </div>
            </div>
            {
                newArray.map((person)=>{
                    return (
                     
                        <Link to={`/exam/${person.id}`} className='examLink'>Take Exam</Link>
                    )
                })
            }

          </div>
      </div>
  )
}